import React, { useState } from "react"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HomeHeader from '../components/homeHeader'
import blankDesktopBackground from '../../static/newTagBackground.jpg'
import BlogCardHome from '../components/blogCardHome'
import blankMobileBackground from '../../static/blankMobileBack.jpg'
import { makeStyles } from '@material-ui/core/styles';
import { Link, graphql } from "gatsby"
import Loader from '../components/loader'
import ComingSoon from '../components/comingSoon'

const useStyles = makeStyles((theme) => ({
    mobile: {
        [theme.breakpoints.up('lg')]: {
            display: 'none'
        },
        textAlign: 'center'
    },
    desktop: {
        [theme.breakpoints.down('md')]: {
            display: 'none'
        },
    },
})
)

const Tags = ({ pageContext, data }) => {
    const { tag } = pageContext
    const posts = data.allMarkdownRemark.edges
    const { edges, totalCount } = data.allMarkdownRemark
    const tagHeader = `${totalCount} post${
        totalCount === 1 ? "" : "s"
        } tagged with "${tag}"`
    const UTag = tag.charAt(0).toUpperCase() + tag.slice(1)

    const classes = useStyles()

    const [loadingIndicator, setLoadingIndicator] = useState(true)

    return (
        <div>
            {loadingIndicator &&
                <Loader load={loadingIndicator} setLoad={setLoadingIndicator} />
            }
            <div style={
                {
                    display: loadingIndicator ? 'none' : 'block'
                }
            }>
                {/* <ComingSoon /> */}
                <div style={{ alignItems: 'center' }}>
                    <div className={classes.desktop} >
                        <HomeHeader pic={blankDesktopBackground} text={`Blogs about ${UTag}`} />
                    </div>
                    <div className={classes.mobile} >
                        <HomeHeader pic={blankMobileBackground} text={UTag} />
                    </div>
                    <Layout>
                        <SEO title='Contact' />
                        <BlogCardHome posts={posts} />
                    </Layout>
                </div>
            </div >
        </div>
    )
}

Tags.propTypes = {
    pageContext: PropTypes.shape({
        tag: PropTypes.string.isRequired,
    }),
    data: PropTypes.shape({
        allMarkdownRemark: PropTypes.shape({
            totalCount: PropTypes.number.isRequired,
            edges: PropTypes.arrayOf(
                PropTypes.shape({
                    node: PropTypes.shape({
                        frontmatter: PropTypes.shape({
                            title: PropTypes.string.isRequired,
                        }),
                        fields: PropTypes.shape({
                            slug: PropTypes.string.isRequired,
                        }),
                    }),
                }).isRequired
            ),
        }),
    }),
}

export default Tags

export const pageQuery = graphql`
  query($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
            excerpt
          fields {
            slug
            readingTime {
                text
            }
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
        title
        description
        link
        buttonText
        image: featured {
          childImageSharp {
            resize(width: 1200) {
              src
              height
              width
            }
          }
        }
        tags
          }
        }
      }
    }
  }
`